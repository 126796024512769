
const keycloakConfig: Keycloak.KeycloakConfig = {
  url: 'https://prihlasenie.csobpoistovna.sk/auth',
  realm: 'Infoportal',
  clientId: 'infoportal-pkce'
};

export const environment = {
  keycloak: keycloakConfig,
  checkLoginIframe: true,
  settings: {
    production: false,
    apiUrl: 'https://infoportal-pilot.csob.sk/infoportal'
  }
};
